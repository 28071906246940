<template>
  <div class="container">
    <router-view :shareid= "shareid" />
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, watchEffect, onMounted, onUnmounted } from "vue";
import { smartdb, smartauth } from "@/firebase/config";
import { collection, where, query, getDocs, onSnapshot } from "firebase/firestore";
import { signInAnonymously } from "firebase/auth";
import defaultImage from '@/assets/images/abc.webp';
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";

// Move these outside component to avoid recreating on each render
//const storage = getStorage();
//const contactDocument = collection(smartdb, "testqrdocuments");

//const contactDocument = collection(smartdb, "contactqrs");

export default {
  setup() {
    const router = useRouter();
    const shareid = ref(null);
    const userCredential=ref('');
    const mysmartcard = ref(null);
    const error = ref(null);
    const myphotoUrl = ref(defaultImage);
    let unsubscribe = null;

    // Memoize the photo URL fetching
  /*  const photoUrlCache = new Map();

    const fetchPhotoUrl = async (photoUrl) => {
      if (photoUrlCache.has(photoUrl)) {
        return photoUrlCache.get(photoUrl);
      }

      const imageRef = storageRef(storage, `userImages/${photoUrl}`);
      try {
        const url = await getDownloadURL(imageRef);
        photoUrlCache.set(photoUrl, url);
        return url;
      } catch (error) {
        console.error("Error fetching image URL:", error);
        return defaultImage;
      }
    };

    const setupRealtimeListener = async (cardId) => {
      console.log("construire querry");
      const q = query(contactDocument, where("_AppShare.cardIdShare", "==", cardId));
      console.log(" querry Ok");

      try {
        console.log("sending querry");
        const querySnapshot = await getDocs(q);
        console.log("query Snapdsshot ok", querySnapshot);
        console.log("getting firt doc");
        const firstDoc = querySnapshot.docs[0];
        console.log("first doc Ok", firstDoc);
        if (firstDoc) {
          const data = firstDoc.data();
          console.log("data object", data);
          mysmartcard.value = {
            ...data,
            SmartContent: data.SmartContent || [],
          };
          console.log("mysmartcard object", mysmartcard);
          //console.log(data);
          // Process the data from the first document
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }



    };
*/
    // Initialize authentication and routing
    watchEffect(async () => {
      try {
        //console.log("start fetching auth");
        //userCredential.value = await signInAnonymously(smartauth);
        //console.log("end fetching auth");
       // if (userCredential.value) {
        //  console.log("Credentials ok, start looking for router");
          await router.isReady();
          console.log("router ready");
          shareid.value = router.currentRoute.value.params.Ad;
          console.log("curentId ok ready");
         /* if (currentId) {
            console.log("strat fetching data");
            await setupRealtimeListener(currentId);
            console.log("end fetching data");
          }*/
        //}
      } catch (error) {
        console.error("Initialization error:", error);
      }
    });

    // Cleanup
 /*   onUnmounted(() => {
      photoUrlCache.clear();
    });
*/
    // Watch for route changes
    /* watchEffect(() => {
       const newId = router.currentRoute.value.params.Ad;
       if (newId && newId !== id.value) {
         id.value = newId;
         if (unsubscribe) {
           unsubscribe();
         }
         setupRealtimeListener(newId);
       }
     });*/
     //console.log("param user Credentiel", userCredential);
     console.log("param sharedId", shareid);

    return { shareid };
  },
};
</script>

<style src="@/assets/styles/shared-styles.css"></style>