<template>
  <!-- Profile Section -->

  <div class="profile-card" v-if="mysmartcard">
    <div class="profile-header">
      <img 
      :src="displayPhotoUrl" 
      :alt="altText"
      class="profile-img" 
      loading="lazy"
      @error="handleImageError"
      :class="{ 'error': imageLoadError }" 
    />

    </div>
    <div class="profile-body">
      <h2> {{ fullName }} </h2>
      <template v-if="hasOrganization">
        <p class="profession">{{ mysmartcard.organizations[0].title }}</p>
        <p class="profession">{{ mysmartcard.organizations[0].company }}</p>

      </template>

      <div class="social-icons" v-if="hasSocialMedia">
        <template v-for="(link, index) in socialLinks" :key="index">
          <a v-bind:href="link.url" target="_blank">
            <Icon :icon="link.icon" width="16" height="16" />
          </a>
        </template>

      </div>

      <div class="profile-footer">
        <button @click="handleDownload" class="btn" :disabled="isDownloading">
          <Icon :icon="isDownloading ? 'line-md:loading-twotone-loop' : 'line-md:downloading-loop'" width="24"
            height="24" style="color: black" />
          <span class="pl-1">{{ downloadButtonText }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//import {saveAs} from 'file-saver';
import { fetchPhotoAsBase64, toVCard } from "@/composables/getvCard";
import { Icon } from "@iconify/vue";
import defaultImage from '@/assets/images/abc.webp';
import { ref, onMounted, computed, watchEffect, onUnmounted } from "vue";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
const storage = getStorage();
const myphotoByte = ref(null);
const isDownloading = ref(false);
const tempurl = ref(defaultImage);
const myphotoUrl = ref(defaultImage);
//const imageRef = storageRef(storage, `userImages/${photoUrl}`);
export default {
  name: "ProfileCard",
  props: {
    mysmartcard: {
      type: Object,
      Required: true,
    },
  },
  components: {
    Icon,
  },
  setup(props) {

    const photoUrlCache = new Map();
    const imageLoadError = ref(false);
    
    const ensurePhotoData = async () => {
      if (!myphotoByte.value ) {
        myphotoByte.value = await fetchPhotoAsBase64(props.myphotoUrl);
      }
      return myphotoByte.value;
    };

    const fetchPhotoUrl = async (photoUrl) => {
      if (photoUrlCache.has(photoUrl)) {
        return photoUrlCache.get(photoUrl);
      }

      const imageRef = storageRef(storage, `userImages/${photoUrl}`);
      try {
        const url = await getDownloadURL(imageRef);
        photoUrlCache.set(photoUrl, url);
        return url;
      } catch (error) {
        console.error("Error fetching image URL:", error);
        return myphotoUrl.value;
      }
    };

    watchEffect(() => {
      (async () => {
        console.log('phto urljdfsjksdjkdfs', tempurl.value);
        tempurl.value = await fetchPhotoUrl(props.mysmartcard.photoUrl);

        myphotoByte.value = await fetchPhotoAsBase64(tempurl.value);
        myphotoUrl.value = myphotoByte.value;
      })();

      console.log('phto url', tempurl.value);
    });

    // Add new computed property for display URL
    const displayPhotoUrl = computed(() => {
      if (!myphotoUrl.value) return defaultImage;  // Add your default image path
      
      // Check if it's already a base64 string
      if (myphotoUrl.value.startsWith('data:image/')) {
        return myphotoUrl.value;
      }
      
      // If it's a base64 string without the prefix, add it
      return `data:image/jpeg;base64,${myphotoUrl.value}`;
    });

    // Add computed property for alt text
    const altText = computed(() => {
      const firstName = props.mysmartcard?.name?.first || '';
      const lastName = props.mysmartcard?.name?.last || '';
      return `${firstName} ${lastName}'s Profile Picture`.trim() || 'Profile Picture';
    });

    // Add error handler
    const handleImageError = (e) => {
      console.error('Image failed to load:', e);
      imageLoadError.value = true;
      e.target.src = defaultImage;  // Add your fallback image path
    };
    
    onUnmounted(() => {
      return () => {
        photoUrlCache.clear();
      };
    }); 
    
    
    const fullName = computed(() =>
      `${props.mysmartcard.name.first} ${props.mysmartcard.name.last}`
    );
    const hasOrganization = computed(() =>
      props.mysmartcard.organizations?.length > 0
    );
    const hasSocialMedia = computed(() =>
      props.mysmartcard.socialMedias?.length > 0
    );
    const socialMediaConfig = {
      x: { icon: 'simple-icons:x' },
      facebook: { icon: 'simple-icons:facebook' },
      linkedIn: { icon: 'simple-icons:linkedin' },
      custom: { icon: 'mdi:external-link' }
    };
    // Memoized social media links
    const socialLinks = computed(() => {
      if (!props.mysmartcard.socialMedias) return [];
      return props.mysmartcard.socialMedias
        .filter(media => socialMediaConfig[media.label])
        .map(media => ({
          icon: socialMediaConfig[media.label].icon,
          url: media.userName,
          label: media.label
        }));
    });

    const downloadButtonText = computed(() =>
      isDownloading.value ? 'Downloading...' : 'Download My Contact'
    );


     // Optimized download handler with error handling

    const handleDownload = async () => {
      if (isDownloading.value) return;

      try {
        isDownloading.value = true;
        const photoData = await ensurePhotoData();

        const vCardData = await toVCard(props.mysmartcard, photoData, { withPhoto: true });
        const blob = new Blob([vCardData], { type: "text/vcard" });
        const url = URL.createObjectURL(blob);
        const filename = `${fullName.value}'s card.vcf`;

        const link = document.createElement('a');
        link.href = url;

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

      } catch (error) {
        console.error('Download failed:', error);
        // You might want to add error handling UI here
      } finally {
        isDownloading.value = false;
      }
    };


    return {
      fullName, hasOrganization, hasSocialMedia, socialLinks, handleDownload,
      isDownloading, myphotoUrl, tempurl,
      downloadButtonText,displayPhotoUrl,
      altText,
      handleImageError,
      imageLoadError
    };

  },

};
</script>

<style src="@/assets/styles/shared-styles.css"></style>
