<template>
  <div class="video-card" v-if="hasMetadata">
    <div class="heading">
      <h2><span class="yellow">My</span> <span class="black">Links</span></h2>
    </div>
    <div >
      
      <div v-for="(data, index) in metadata" :key="index">
        <a v-bind:href="data.url" target="_blank" rel="noopener noreferrer" class="linkcard-a">
        <Card class="linkcard" v-if="data.image || data.title || data.description">
          <template #header v-if="data.image">
            <img alt="user header" v-bind:src="data.image" class="profile-img"/>
          </template>
          <template #title>
            {{ data.title }}</template>
          <template #content>
            <p class="m-0">
              {{ data.description }}
            </p>
          </template>
        </Card>
    </a>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, watchEffect } from "vue";
import Card from "primevue/card";
import { fetchMetadata } from "@/composables/getLinkInfo";

export default {
  name: "LinksPage",
  props: {
    mysmartcard: {
      type: Object,
      Required: true,
    },
  },
  components: {
    Card,
  },

  setup(props) {
    const metadata = ref([]);
    const hasMetadata = computed(() => 
      metadata.value && metadata.value.length > 0
    );
    if (props.mysmartcard.SmartContent) {
      watchEffect(async () => {
        const linkContents = computed(() => {
          return props.mysmartcard.SmartContent.filter(
            (content) => content.contentType === "linkContent"
          );
        });

        metadata.value = await fetchMetadata(linkContents.value);

        if (metadata.value) {
          console.log("Metadata value is ; ", metadata.value);
        }
      });

      return { metadata, hasMetadata };
    }
  },
};
</script>

<style src="@/assets/styles/shared-styles.css">
</style>
