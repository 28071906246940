<template>

  <div class="container" v-if="mysmartcard">
  <ProfileCard :mysmartcard="mysmartcard" />
  <ContactInfo :mysmartcard="mysmartcard" />
  <VideoPage :mysmartcard="mysmartcard" />
    <LinksPage :mysmartcard="mysmartcard" />
  </div>
  <div v-else>
    <LoadingPage />
  </div>
</template>

<script>
// @ is an alias to /src
import ProfileCard from "@/components/ProfileCard.vue";
import LoadingPage from "@/components/LoadingPage.vue";
import ContactInfo from "@/components/ContactInfo.vue";
import VideoPage from '@/components/VideosPage.vue';
import LinksPage from "@/components/LinksPage.vue";
import { ref, watchEffect, onMounted, onUnmounted } from "vue";
import { smartdb, smartauth } from "@/firebase/config";
import { collection, where, query, getDocs } from "firebase/firestore";
import { signInAnonymously } from "firebase/auth";


// Move these outside component to avoid recreating on each render
//const storage = getStorage();
//const contactDocument = collection(smartdb, "testqrdocuments");
const contactDocument = collection(smartdb, "contactqrs");
// Move these outside the component if possible
const userCredential = ref(null);
const idDoc = ref(null);
const mysmartcard = ref(null);


// Separate authentication logic
const handleAuthentication = async (smartauth) => {
  try {
    if (!userCredential.value) {
      console.log("start fetching auth");
      userCredential.value = await signInAnonymously(smartauth);
      console.log("end fetching auth");
    }
    return userCredential.value;
  } catch (error) {
    console.error("Authentication failed:", error);
    return null;
  }
};

// Separate query creation logic
const createContactQuery = (shareId) => {
  if (!shareId) return null;
  return query(contactDocument, where("_AppShare.cardIdShare", "==", shareId));
};




export default {
  name: "HomeView",
  components: {
    ProfileCard,
    ContactInfo,
    VideoPage,
    LinksPage,
    LoadingPage,

  },
  props: {
    shareid: {
      type: String,
      Required: true,
    },
  },

  setup(props) {
    // Use a debounced watch instead of watchEffect for better performance
    const queryInstance = ref(null);

    watchEffect(() => {
      // Wrap in async IIFE
      (async () => {
        try {
          const credential = await handleAuthentication(smartauth);

          if (!credential) {
            console.error("No valid credentials");
            return;
          }

          // Only update if shareid changed
          if (idDoc.value !== props.shareid) {
            idDoc.value = props.shareid;
            if (idDoc.value) {
              console.log("Creating query");
              //queryInstance.value = createContactQuery(idDoc.value);
              console.log("sending querry");
              const querySnapshot = await getDocs( createContactQuery(idDoc.value));
              console.log("query Snapdsshot ok", querySnapshot);
              console.log("getting firt doc");
              const firstDoc = querySnapshot.docs[0];
              console.log("first doc Ok", firstDoc);
              if (firstDoc) {
                //const data = firstDoc.data();
                //console.log("data object", data);
                mysmartcard.value = firstDoc.data();
                console.log("mysmartcard object", mysmartcard);
              }

            }
          }
        } catch (error) {
          console.error("Error in watch effect:", error);
        }
      })();
    }, {
      // Add debounce to prevent too frequent executions
      flush: 'post',
      debounce: 300
    });

    return {
      userCredential,
      idDoc,
      mysmartcard,
      
    };
  }
};
/*setup(props) {
  const mysmartcard = ref(null);
  const userCredential=ref('');
  const idDoc=ref('')

  watchEffect(async () => {
     console.log("start fetching auth");
      userCredential.value = await signInAnonymously(smartauth);
      console.log("end fetching auth");
      if (userCredential.value) {
        console.log("Credentials ok, start looking for router");
        idDoc.value  = props.shareid;
        if(idDoc.value){
          console.log("strat fetching data");
          console.log("construire querry");
          const q = query(contactDocument, where("_AppShare.cardIdShare", "==", idDoc.value));
          console.log(" querry Ok");
          //await setupRealtimeListener(currentId);
          console.log("end fetching data");
        }

  }

  }
  );
 return { mysmartcard, idDoc }
}*/




</script>

<style src="@/assets/styles/shared-styles.css"></style>
